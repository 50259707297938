import React, { useEffect, useState } from 'react';

import Header from '../../hooks/common/header';
import { XaiKey } from '../../hooks/common/key';
import SystemMessageManager from '../../hooks/grok/systemmessagemanager';
import ChatDataLoader from '../../hooks/grok/chatdataloader';
import ChatDataDisplay from '../../hooks/grok/chatdatadisplay';
import ChatDataDeleter from '../../hooks/grok/chatdatadeleter';
import ChatDatasDeleteButton from '../../hooks/grok/chatdatasdeletebutton';
import ChatDatasDeleter from '../../hooks/grok/chatdatasdeleter';
import ChatSendButton from '../../hooks/grok/chatsendbutton';
import ChatInitButton from '../../hooks/grok/chatinitbutton';
import ChatDisplay from '../../hooks/grok/chatdisplay';

import { ChatData } from '../../@types/data';
import { Messages } from '../../@types/messages';

interface GrokProps {
title: string;
roomType: string;
}

const Grok: React.FC<GrokProps> = ({
title,
roomType
}) => {
const [totalCount, setTotalCount] = useState<number>(0);
const [currentPage, setCurrentPage] = useState<number>(1);
const [totalPage, setTotalPage] = useState<number>(1);
const [userQuery, setUserQuery] = useState<string>('');
const [searchQuery, setSearchQuery] = useState<string>('');
const [chatData, setChatData] = useState<ChatData[]>([]);
const [model, setModel] = useState<string>('grok-beta');
const [userInput, setUserInput] = useState<string>('');
const [file, setFile] = useState<File | null>(null);
const [messages, setMessages] = useState<Messages[]>([]);
const [systemMessage, setSystemMessage] = useState<string>('');
const [sourceLanguage, setSourceLanguage] = useState<string>('영어');
const [arriveLanguage, setArriveLanguage] = useState<string>('한국어');
const [saveResult, setSaveResult] = useState<string>('');
const [deleteTarget, setDeleteTarget] = useState<number | null>(null);
const [deleteTargets, setDeleteTargets] = useState<string>('');
const [deleteResult, setDeleteResult] = useState<string>('');
const [deleteResults, setDeleteResults] = useState<string>('');
const [chatLog, setChatLog] = useState<React.ReactNode>('');
const [used, setUsed] = useState<React.ReactNode>('');
const [isSending, setIsSending] = useState<boolean>(false);
const [chatDataLoad, setChatDataLoad] = useState<boolean>(false);
const [grokApiKey, setGrokApiKey] = useState<string>('');
const [akNo, setAkNo] = useState<number>(0);

useEffect(() => {
if (systemMessage) {
setMessages([
{ role: 'system', content: systemMessage },
]);
}
}, [systemMessage]);

return (
<>
<Header
title={title}
/>

<XaiKey
grokApiKey={grokApiKey}
setGrokApiKey={setGrokApiKey}
setAkNo={setAkNo}
/>

{grokApiKey && (
<>
<SystemMessageManager
roomType={roomType}
sourceLanguage={sourceLanguage}
arriveLanguage={arriveLanguage}
setSystemMessage={setSystemMessage}
/>

<ChatDataLoader
roomType={roomType}
deleteResult={deleteResult}
setDeleteResult={setDeleteResult}
deleteResults={deleteResults}
chatDataLoad={chatDataLoad}
setTotalCount={setTotalCount}
currentPage={currentPage}
searchQuery={searchQuery}
setUserQuery={setUserQuery}
setChatData={setChatData}
akNo={akNo}
/>

<ChatDataDisplay
roomType={roomType}
totalCount={totalCount}
currentPage={currentPage}
setCurrentPage={setCurrentPage}
totalPage={totalPage}
setTotalPage={setTotalPage}
userQuery={userQuery}
setUserQuery={setUserQuery}
setSearchQuery={setSearchQuery}
chatData={chatData}
setDeleteTarget={setDeleteTarget}
isSending={isSending}
setChatDataLoad={setChatDataLoad}
systemMessage={systemMessage}
messages={messages}
setMessages={setMessages}
/>

<ChatDataDeleter
roomType={roomType}
setCurrentPage={setCurrentPage}
deleteTarget={deleteTarget}
setDeleteTarget={setDeleteTarget}
setDeleteResult={setDeleteResult}
/>

<ChatDatasDeleteButton
setDeleteTargets={setDeleteTargets}
roomType={roomType}
chatData={chatData}
/>

<ChatDatasDeleter
setCurrentPage={setCurrentPage}
deleteTargets={deleteTargets}
setDeleteTargets={setDeleteTargets}
deleteResults={deleteResults}
setDeleteResults={setDeleteResults}
akNo={akNo}
/>

<ChatSendButton
title={title}
roomType={roomType}
model={model}
setModel={setModel}
userInput={userInput}
setUserInput={setUserInput}
file={file}
setFile={setFile}
systemMessage={systemMessage}
setSystemMessage={setSystemMessage}
sourceLanguage={sourceLanguage}
setSourceLanguage={setSourceLanguage}
arriveLanguage={arriveLanguage}
setArriveLanguage={setArriveLanguage}
messages={messages}
setMessages={setMessages}
isSending={isSending}
setIsSending={setIsSending}
setCurrentPage={setCurrentPage}
setSearchQuery={setSearchQuery}
setChatDataLoad={setChatDataLoad}
akNo={akNo}
/>

<ChatInitButton
systemMessage={systemMessage}
setMessages={setMessages}
chatLog={chatLog}
setChatLog={setChatLog}
setUsed={setUsed}
setSaveResult={setSaveResult}
setChatDataLoad={setChatDataLoad}
isSending={isSending}
/>

<ChatDisplay
roomType={roomType}
chatLog={chatLog}
setChatLog={setChatLog}
used={used}
setUsed={setUsed}
model={model}
messages={messages}
setMessages={setMessages}
userInput={userInput}
setUserInput={setUserInput}
setFile={setFile}
saveResult={saveResult}
setSaveResult={setSaveResult}
setDeleteResults={setDeleteResults}
setIsSending={setIsSending}
chatDataLoad={chatDataLoad}
grokApiKey={grokApiKey}
akNo={akNo}
/>
</>
)}
</>
);
}

export default Grok;
