import React, { useState } from 'react';

import Header from '../../hooks/common/header';
import { OpenaiKey } from '../../hooks/common/key';
import ChatSendButton from '../../hooks/vision/chatsendbutton';
import ChatInitButton from '../../hooks/vision/chatinitbutton';
import ChatDisplay from '../../hooks/vision/chatdisplay';

import { VisionMessages } from '../../@types/messages';

interface VisionProps {
title: string;
roomType: string;
}

const Vision: React.FC<VisionProps> = ({
title,
roomType
}) => {
const [model, setModel] = useState<string>('gpt-4o-mini');
const [userInput, setUserInput] = useState<string>('다음 이미지를 시각장애인에게 꼼꼼하게 구체적으로 설명해줘.');
const [file, setFile] = useState<File | null>(null);
const [messages, setMessages] = useState<VisionMessages[]>([]);
const [chatLog, setChatLog] = useState<React.ReactNode>('');
const [used, setUsed] = useState<React.ReactNode>('');
const [isVoiceEnabled, setIsVoiceEnabled] = useState<boolean>(false);
const [audioSrc, setAudioSrc] = useState<string>('');
const [isSending, setIsSending] = useState<boolean>(false);
const [apiKey, setApiKey] = useState<string>('');

return (
<>
<Header
title={title}
/>

<OpenaiKey
apiKey={apiKey}
setApiKey={setApiKey}
/>

{apiKey && (
<>
<ChatSendButton
title={title}
roomType={roomType}
model={model}
setModel={setModel}
userInput={userInput}
setUserInput={setUserInput}
file={file}
setFile={setFile}
messages={messages}
setMessages={setMessages}
isVoiceEnabled={isVoiceEnabled}
setIsVoiceEnabled={setIsVoiceEnabled}
isSending={isSending}
setIsSending={setIsSending}
/>

<ChatInitButton
setMessages={setMessages}
chatLog={chatLog}
setChatLog={setChatLog}
isVoiceEnabled={isVoiceEnabled}
setIsVoiceEnabled={setIsVoiceEnabled}
setAudioSrc={setAudioSrc}
isSending={isSending}
/>

<ChatDisplay
roomType={roomType}
chatLog={chatLog}
setChatLog={setChatLog}
used={used}
setUsed={setUsed}
model={model}
messages={messages}
setMessages={setMessages}
userInput={userInput}
setUserInput={setUserInput}
setFile={setFile}
isVoiceEnabled={isVoiceEnabled}
audioSrc={audioSrc}
setAudioSrc={setAudioSrc}
setIsSending={setIsSending}
apiKey={apiKey}
/>
</>
)}
</>
);
}

export default Vision;
